import React, { useRef, useState, useEffect, useMemo, Suspense, useContext, useCallback } from "react";
import { useLoader, useFrame, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { useAnimations, useGLTF, OrbitControls, useVideoTexture, TransformControls } from '@react-three/drei';
import { SessionContext } from "../AppStoryPlayer";
import { useCountListener, emitCount } from '../events/bridge'

const ExpModel = ({ vizItem, modelPath, scale = 40, rotation = [0, 0, 0], position = [0, 0, 0], positionEnd = [0, 0, 0], duration = 2, setOrbitActive, thisIndex, navOmitMain = false }) => {
  const [hovered, setHovered] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingAxis, setIsDraggingAxis] = useState(null);
  const [positionStart, setPositionStart] = useState(position);
  const [hoveredAxis, setHoveredAxis] = useState(null);
  const { size, viewport, camera, gl } = useThree();
  const aspect = size.width / viewport.width;
  const transform = useRef();
  const orbit = useRef();
  const modelRef = useRef();
  const boundingBoxRef = useRef();
  const wireframeRef = useRef();
  const groupRef = useRef();
  const dragStart = useRef(new THREE.Vector3());
  const dragStartPosition = useRef(new THREE.Vector3());

  const [sessionData, setSessionData] = useContext(SessionContext);

  const model = useLoader(GLTFLoader, modelPath);
  const xArrowModel = useLoader(GLTFLoader, "/components/b3-admin-model-arrows-x.glb");
  const yArrowModel = useLoader(GLTFLoader, "/components/b3-admin-model-arrows-y.glb");
  const zArrowModel = useLoader(GLTFLoader, "/components/b3-admin-model-arrows-z.glb");

  const { DEG2RAD } = THREE.MathUtils;

  useEffect(() => {
    if (transform.current) {
      const controls = transform.current;
      controls.setMode("translate");
      const callback = event => (orbit.current.enabled = !event.value);
      controls.addEventListener("dragging-changed", callback);
      return () => controls.removeEventListener("dragging-changed", callback);
    }
  });

  function Screen({ src, position, scale, mediaWidth = 16, mediaHeight = 9 }) {
    const [video, setVideo] = useState();
    const ratio = 16 / 9;
    const r = useMemo(() => (video ? video.videoWidth / video.videoHeight : ratio), [video, ratio]);

    return (
      <mesh position={position} scale={scale}>
        <planeGeometry args={[mediaWidth, mediaHeight]} />
        <Suspense fallback={<meshStandardMaterial side={THREE.DoubleSide} wireframe />}>
          <VideoMaterial src={src} />
        </Suspense>
      </mesh>
    );
  }

  function VideoMaterial({ src }) {
    const texture = useVideoTexture(src);
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.x = 1;
    texture.offset.x = 1;
    return <meshStandardMaterial map={texture} toneMapped={false} opacity={1} />;
  }

  let mixer;
  if (model.animations.length) {
    mixer = new THREE.AnimationMixer(model.scene);
    model.animations.forEach(clip => {
      const action = mixer.clipAction(clip);
      action.play();
    });
  }

  const getMousePosition = useCallback((event) => {
    const vec = new THREE.Vector3();
    const pos = new THREE.Vector3();

    vec.set(
      (event.clientX / size.width) * 2 - 1,
      -(event.clientY / size.height) * 2 + 1,
      0.5
    );

    vec.unproject(camera);
    vec.sub(camera.position).normalize();
    const distance = -camera.position.z / vec.z;
    pos.copy(camera.position).add(vec.multiplyScalar(distance));

    return pos;
  }, [camera, size]);

  const onPointerDown = useCallback((event, axis) => {
    event.stopPropagation();
    setIsDragging(true);
    setIsDraggingAxis(axis);
    setOrbitActive(0);
    gl.domElement.style.cursor = 'grabbing';
    const mousePos = getMousePosition(event);
    dragStart.current.set(mousePos.x, mousePos.y, mousePos.z);
    dragStartPosition.current.copy(groupRef.current.position);
  }, [gl, getMousePosition, setOrbitActive]);

  const onPointerMove = useCallback((event) => {
    if (!isDragging) return;
    const currentPosition = getMousePosition(event);
    const dragDelta = new THREE.Vector3(
      currentPosition.x - dragStart.current.x,
      currentPosition.y - dragStart.current.y,
      currentPosition.z - dragStart.current.z
    );

    switch (isDraggingAxis) {
      case 'x':
        groupRef.current.position.x = dragStartPosition.current.x + dragDelta.x;
        break;
      case 'y':
        groupRef.current.position.y = dragStartPosition.current.y + dragDelta.y;
        break;
      case 'z':
        groupRef.current.position.z = dragStartPosition.current.z + dragDelta.z;
        break;
    }
  }, [isDragging, getMousePosition, isDraggingAxis]);

  const onPointerUp = useCallback((event) => {
    if (isDragging) {
      const currentPosition = getMousePosition(event);
      const isStillHovering = (
        isDraggingAxis === 'x' && Math.abs(currentPosition.x - dragStartPosition.current.x) < 1 ||
        isDraggingAxis === 'y' && Math.abs(currentPosition.y - dragStartPosition.current.y) < 1 ||
        isDraggingAxis === 'z' && Math.abs(currentPosition.z - dragStartPosition.current.z) < 1
      );

      emitCount({
        uuid: vizItem.guid,
        vizItem: vizItem,
        x: groupRef.current.position.x - dragStartPosition.current.x,
        y: groupRef.current.position.y - dragStartPosition.current.y,
        z: groupRef.current.position.z
      });
    }

    setIsDragging(false);
    setIsDraggingAxis(null);
    setOrbitActive(1);
    gl.domElement.style.cursor = 'auto';
  }, [gl, setOrbitActive, isDragging, isDraggingAxis, vizItem, getMousePosition]);

  useEffect(() => {
    const domElement = gl.domElement;
    domElement.addEventListener('pointermove', onPointerMove);
    domElement.addEventListener('pointerup', onPointerUp);
    return () => {
      domElement.removeEventListener('pointermove', onPointerMove);
      domElement.removeEventListener('pointerup', onPointerUp);
    };
  }, [gl, onPointerMove, onPointerUp]);

  useFrame((state, delta) => {
    mixer?.update(delta);

    if (modelRef.current && boundingBoxRef.current && wireframeRef.current) {
      const box = new THREE.Box3().setFromObject(modelRef.current);
      const size = new THREE.Vector3();
      box.getSize(size);
      const sizeMult = .7;
      const boxSize = [size.x + sizeMult, size.y + sizeMult, size.z + sizeMult];
      boundingBoxRef.current.scale.set(...boxSize);
      wireframeRef.current.scale.set(...boxSize);
      const newPosition = [
        modelRef.current.position.x,
        modelRef.current.position.y + size.y / 2,
        modelRef.current.position.z
      ];
      boundingBoxRef.current.position.set(...newPosition);
      wireframeRef.current.position.set(...newPosition);
    }
  });

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto';
  }, [hovered]);

  const activateSidebar = (id) => {
    try {
      const el1 = document.querySelector('div[data-itemguid="' + id + '"]');
      el1.classList.add('active');
      var accordionPanels = document.querySelectorAll('.accordion__panel');
      for (var i = 0; i < accordionPanels.length; i++) {
      var panel = accordionPanels[i];
      if (panel.getAttribute("data-itemguid") === id) {
        panel.removeAttribute("hidden");
      } else {
        panel.setAttribute("hidden", "");
      }
      }
      const buttons = document.querySelectorAll('.accordion__button');
      for (var i = 0; i < buttons.length; i++) {
      var button = buttons[i];
      if (button.getAttribute("data-itemguid") === id) {
        button.setAttribute('aria-expanded', 'true');
        button.classList.add('last-accordion-button');
      } else {
        button.setAttribute('aria-expanded', 'false');
        button.classList.remove('last-accordion-button');
      }
      }
    } catch (error) {
      //console.error('Error activating sidebar:', error);
    }
  };

  const deg2radian = (degrees) => {
    var pi = Math.PI;
    return degrees * (pi / 180);
  };

  if (sessionData.playModeActive != 1) {
    return (
      <group ref={groupRef} position={positionStart}>
        {vizItem.mediaEmbed && (
          <Screen
            src={vizItem.mediaEmbed.source}
            position={[vizItem.mediaEmbed.offset.x, vizItem.mediaEmbed.offset.y, vizItem.mediaEmbed.offset.z]}
            scale={vizItem.mediaEmbed.scale}
            mediaHeight={vizItem.mediaEmbed.mediaHeight || 9}
            mediaWidth={vizItem.mediaEmbed.mediaWidth || 16}
          />
        )}
        <primitive
          ref={modelRef}
          object={model.animations.length > 0 ? model.scene : model.scene.clone()}
          scale={scale}
          rotation={[deg2radian(rotation[0]) || 0, deg2radian(rotation[1]) || 0, deg2radian(rotation[2]) || 0]}
          onClick={(e) => activateSidebar(vizItem.guid)}
        />
        {!navOmitMain && (
          <>
            <mesh
              ref={boundingBoxRef}
              onPointerOver={() => setHovered(true)}
              onPointerOut={() => setHovered(false)}
            >
              <boxGeometry args={[1, 1, 1]} />
              <meshPhongMaterial color="#38BDF8" transparent opacity={hoveredAxis ? 0 : (hovered ? 0.3 : 0)} />
            </mesh>
            <lineSegments ref={wireframeRef}>
              <edgesGeometry args={[new THREE.BoxGeometry(1, 1, 1)]} />
              <lineDashedMaterial color="#38BDF8" dashSize={0.1} gapSize={0.05} opacity={hoveredAxis ? 0 : (hovered ? 1 : 0)} transparent />
            </lineSegments>
          </>
        )}
        {(thisIndex === sessionData.activeIndex || thisIndex === -1) && (
          <>
            <group
              position={[1, 1, 11]}
              onPointerDown={(e) => onPointerDown(e, 'x')}
              onPointerOver={() => {
                gl.domElement.style.cursor = 'grab';
                setHoveredAxis('x');
              }}
              onPointerOut={() => {
                gl.domElement.style.cursor = 'auto';
                setHoveredAxis(null);
              }}
            >
              <mesh>
                <boxGeometry args={[2, 1, 1]} />
                <meshPhongMaterial color="#38BDF8" transparent opacity={hoveredAxis === 'x' ? .2 : 0} />
              </mesh>
              <primitive object={xArrowModel.scene.clone()} scale={0.5} />
            </group>
            <group
              position={[-1, 1, 11]}
              onPointerDown={(e) => onPointerDown(e, 'y')}
              onPointerOver={() => {
                gl.domElement.style.cursor = 'grab';
                setHoveredAxis('y');
              }}
              onPointerOut={() => {
                gl.domElement.style.cursor = 'auto';
                setHoveredAxis(null);
              }}
            >
              <mesh>
                <boxGeometry args={[1, 2, 1]} />
                <meshPhongMaterial color="#38BDF8" transparent opacity={hoveredAxis === 'y' ? .2 : 0} />
              </mesh>
              <primitive object={yArrowModel.scene.clone()} scale={0.5} />
            </group>
          </>
        )}
      </group>
    );
  } else {
    return (
      <group position={positionStart}>
        {vizItem.mediaEmbed && (
          <Screen
            src={vizItem.mediaEmbed.source}
            position={[vizItem.mediaEmbed.offset.x, vizItem.mediaEmbed.offset.y, vizItem.mediaEmbed.offset.z]}
            scale={vizItem.mediaEmbed.scale}
            mediaHeight={vizItem.mediaEmbed.mediaHeight || 9}
            mediaWidth={vizItem.mediaEmbed.mediaWidth || 16}
          />
        )}
        <primitive
          ref={modelRef}
          object={model.animations.length > 0 ? model.scene : model.scene.clone()}
          scale={scale}
          rotation={[deg2radian(rotation[0]) || 0, deg2radian(rotation[1]) || 0, deg2radian(rotation[2]) || 0]}
        />
      </group>
    );
  }
};

export default React.memo(ExpModel);

// import React, { useRef, useState, useEffect, useMemo, Suspense, useContext, useCallback } from "react";
// import { useLoader, useFrame, useThree } from "@react-three/fiber";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// import * as THREE from "three";
// import { useAnimations, useGLTF, OrbitControls, useVideoTexture, TransformControls } from '@react-three/drei';
// import { SessionContext } from "../AppStoryPlayer";
// import { useCountListener, emitCount } from '../events/bridge'

// const ExpModel = ({ vizItem, modelPath, scale = 40, rotation = [0, 0, 0], position = [0, 0, 0], positionEnd = [0, 0, 0], duration = 2, setOrbitActive, thisIndex, navOmitMain = false }) => {
//   const [hovered, setHovered] = useState(false);
//   const [isDragging, setIsDragging] = useState(false);
//   const [isDraggingAxis, setIsDraggingAxis] = useState(null);
//   const [positionStart, setPositionStart] = useState(position);
//   const [hoveredAxis, setHoveredAxis] = useState(null);
//   const { size, viewport, camera, gl } = useThree();
//   const aspect = size.width / viewport.width;
//   const transform = useRef();
//   const orbit = useRef();
//   const modelRef = useRef();
//   const boundingBoxRef = useRef();
//   const wireframeRef = useRef();
//   const groupRef = useRef();
//   const dragStart = useRef(new THREE.Vector3());
//   const dragStartPosition = useRef(new THREE.Vector3());

//   const [sessionData, setSessionData] = useContext(SessionContext);


//   console.log('NavOmitMain: ', navOmitMain); 

//   // Load the main model and arrow models
//   const model = useLoader(GLTFLoader, modelPath);
//   const xArrowModel = useLoader(GLTFLoader, "/components/b3-admin-model-arrows-x.glb");
//   const yArrowModel = useLoader(GLTFLoader, "/components/b3-admin-model-arrows-y.glb");
//   const zArrowModel = useLoader(GLTFLoader, "/components/b3-admin-model-arrows-z.glb");

//   const { DEG2RAD } = THREE.MathUtils;

//   useEffect(() => {
//     if (transform.current) {
//       const controls = transform.current;
//       controls.setMode("translate");
//       const callback = event => (orbit.current.enabled = !event.value);
//       controls.addEventListener("dragging-changed", callback);
//       return () => controls.removeEventListener("dragging-changed", callback);
//     }
//   });

//   function Screen({ src, position, scale, mediaWidth = 16, mediaHeight = 9 }) {
//     const [video, setVideo] = useState();
//     const ratio = 16 / 9;
//     const r = useMemo(() => (video ? video.videoWidth / video.videoHeight : ratio), [video, ratio]);

//     return (
//       <mesh position={position} scale={scale}>
//         <planeGeometry args={[mediaWidth, mediaHeight]} />
//         <Suspense fallback={<meshStandardMaterial side={THREE.DoubleSide} wireframe />}>
//           <VideoMaterial src={src} />
//         </Suspense>
//       </mesh>
//     );
//   }

//   function VideoMaterial({ src }) {
//     const texture = useVideoTexture(src);
//     texture.wrapS = THREE.RepeatWrapping;
//     texture.wrapT = THREE.RepeatWrapping;
//     texture.repeat.x = 1;
//     texture.offset.x = 1;
//     return <meshStandardMaterial map={texture} toneMapped={false} opacity={1} />;
//   }

//   let mixer;
//   if (model.animations.length) {
//     mixer = new THREE.AnimationMixer(model.scene);
//     model.animations.forEach(clip => {
//       const action = mixer.clipAction(clip);
//       action.play();
//     });
//   }

//   const getMousePosition = useCallback((event) => {
//     const vec = new THREE.Vector3();
//     const pos = new THREE.Vector3();

//     vec.set(
//       (event.clientX / size.width) * 2 - 1,
//       -(event.clientY / size.height) * 2 + 1,
//       0.5
//     );

//     vec.unproject(camera);
//     vec.sub(camera.position).normalize();
//     const distance = -camera.position.z / vec.z;
//     pos.copy(camera.position).add(vec.multiplyScalar(distance));

//     return pos;
//   }, [camera, size]);

//   const onPointerDown = useCallback((event, axis) => {
//     event.stopPropagation();
//     setIsDragging(true);
//     setIsDraggingAxis(axis);
//     setOrbitActive(0);
//     gl.domElement.style.cursor = 'grabbing';
//     const mousePos = getMousePosition(event);
//     dragStart.current.set(mousePos.x, mousePos.y, mousePos.z);
//     dragStartPosition.current.copy(groupRef.current.position);
//   }, [gl, getMousePosition, setOrbitActive]);

//   const onPointerMove = useCallback((event) => {
//     if (!isDragging) return;
//     const currentPosition = getMousePosition(event);
//     const dragDelta = new THREE.Vector3(
//       currentPosition.x - dragStart.current.x,
//       currentPosition.y - dragStart.current.y,
//       currentPosition.z - dragStart.current.z
//     );

//     switch (isDraggingAxis) {
//       case 'x':
//         groupRef.current.position.x = dragStartPosition.current.x + dragDelta.x;
//         break;
//       case 'y':
//         groupRef.current.position.y = dragStartPosition.current.y + dragDelta.y;
//         break;
//       case 'z':
//         // Fix: Use dragDelta.z instead of dragDelta.y for z-axis movement
//         groupRef.current.position.z = dragStartPosition.current.z + dragDelta.z;
//         break;
//     }

//   }, [isDragging, getMousePosition, isDraggingAxis]);

//   const onPointerUp = useCallback((event) => {
//     if (isDragging) {
//       const currentPosition = getMousePosition(event);
//       const isStillHovering = (
//         isDraggingAxis === 'x' && Math.abs(currentPosition.x - dragStartPosition.current.x) < 1 ||
//         isDraggingAxis === 'y' && Math.abs(currentPosition.y - dragStartPosition.current.y) < 1 ||
//         isDraggingAxis === 'z' && Math.abs(currentPosition.z - dragStartPosition.current.z) < 1
//       );

//       //if (isStillHovering) {
//       //console.log('Position: ', groupRef.current.position);
//       emitCount({
//         uuid: vizItem.guid,
//         vizItem: vizItem,
//         x: groupRef.current.position.x - dragStartPosition.current.x,
//         y: groupRef.current.position.y - dragStartPosition.current.y,
//         z: groupRef.current.position.z
//       });
//       //}
//     }

//     setIsDragging(false);
//     setIsDraggingAxis(null);
//     setOrbitActive(1);
//     gl.domElement.style.cursor = 'auto';
//   }, [gl, setOrbitActive, isDragging, isDraggingAxis, vizItem, getMousePosition]);

//   useEffect(() => {
//     const domElement = gl.domElement;
//     domElement.addEventListener('pointermove', onPointerMove);
//     domElement.addEventListener('pointerup', onPointerUp);
//     return () => {
//       domElement.removeEventListener('pointermove', onPointerMove);
//       domElement.removeEventListener('pointerup', onPointerUp);
//     };
//   }, [gl, onPointerMove, onPointerUp]);

//   useFrame((state, delta) => {
//     mixer?.update(delta);

//     // Update bounding box position and size
//     if (modelRef.current && boundingBoxRef.current && wireframeRef.current) {
//       const box = new THREE.Box3().setFromObject(modelRef.current);
//       const size = new THREE.Vector3();
//       box.getSize(size);
//       const sizeMult = .7;
//       const boxSize = [size.x + sizeMult, size.y + sizeMult, size.z + sizeMult];
//       boundingBoxRef.current.scale.set(...boxSize);
//       wireframeRef.current.scale.set(...boxSize);
//       const newPosition = [
//         modelRef.current.position.x,
//         modelRef.current.position.y + size.y / 2,
//         modelRef.current.position.z
//       ];
//       boundingBoxRef.current.position.set(...newPosition);
//       wireframeRef.current.position.set(...newPosition);
//     }
//   });

//   useEffect(() => {
//     document.body.style.cursor = hovered ? 'pointer' : 'auto';
//   }, [hovered]);

//   const activateSidebar = (id) => {
//     const el1 = document.querySelector('div[data-itemguid="' + id + '"]');
//     el1.classList.add('active');
//     var accordionPanels = document.querySelectorAll('.accordion__panel');
//     for (var i = 0; i < accordionPanels.length; i++) {
//       var panel = accordionPanels[i];
//       if (panel.getAttribute("data-itemguid") === id) {
//         panel.removeAttribute("hidden");
//       }
//       else {
//         panel.setAttribute("hidden", "");
//       }
//     }
//     const buttons = document.querySelectorAll('.accordion__button');
//     for (var i = 0; i < buttons.length; i++) {
//       var button = buttons[i];
//       if (button.getAttribute("data-itemguid") === id) {
//         button.setAttribute('aria-expanded', 'true');
//         button.classList.add('last-accordion-button');
//       }
//       else {
//         button.setAttribute('aria-expanded', 'false');
//         button.classList.remove('last-accordion-button');
//       }
//     }
//   };

//   const deg2radian = (degrees) => {
//     var pi = Math.PI;
//     return degrees * (pi / 180);
//   };

//   // return (
//   //   <group
//   //     position={positionStart}
//   //   >
//   //     {vizItem.mediaEmbed ?
//   //       <Screen
//   //         src={vizItem.mediaEmbed.source}
//   //         position={[vizItem.mediaEmbed.offset.x, vizItem.mediaEmbed.offset.y, vizItem.mediaEmbed.offset.z]}
//   //         scale={vizItem.mediaEmbed.scale}
//   //         mediaHeight={vizItem.mediaEmbed.mediaHeight || 9}
//   //         mediaWidth={vizItem.mediaEmbed.mediaWidth || 16}
//   //       />
//   //       : null
//   //     }
//   //     <primitive
//   //       object={model.animations.length > 0 ? model.scene : model.scene.clone()}
//   //       scale={scale}
//   //       onPointerOver={() => { model.animations.length > 0 ? null : setHovered(true) }}
//   //       onPointerOut={() => { model.animations.length > 0 ? null : setHovered(false) }}
//   //       onClick={(e) => activateSidebar(vizItem.guid)}
//   //     />
//   //   </group>
//   // );

//   if (sessionData.playModeActive != 1) {

//     //console.log('model.scene: ', model.scene);

//     return (
//       <group ref={groupRef} position={positionStart}>
//         {vizItem.mediaEmbed && (
//           <Screen
//             src={vizItem.mediaEmbed.source}
//             position={[vizItem.mediaEmbed.offset.x, vizItem.mediaEmbed.offset.y, vizItem.mediaEmbed.offset.z]}
//             scale={vizItem.mediaEmbed.scale}
//             mediaHeight={vizItem.mediaEmbed.mediaHeight || 9}
//             mediaWidth={vizItem.mediaEmbed.mediaWidth || 16}
//           />
//         )}
//         <primitive
//           ref={modelRef}
//           object={model.animations.length > 0 ? model.scene : model.scene.clone()}
//           scale={scale}
//           rotation={[deg2radian(rotation[0]) || 0, deg2radian(rotation[1]) || 0, deg2radian(rotation[2]) || 0]}
//           onClick={(e) => activateSidebar(vizItem.guid)}
//         />


//         {!navOmitMain ?
//           <>
//             <mesh
//               ref={boundingBoxRef}
//               onPointerOver={() => setHovered(true)}
//               onPointerOut={() => setHovered(false)} >
//               <boxGeometry args={[1, 1, 1]} />
//               <meshPhongMaterial color="#38BDF8" transparent opacity={hoveredAxis ? 0 : (hovered ? 0.3 : 0)} />
//             </mesh>
//             <lineSegments ref={wireframeRef}>
//               <edgesGeometry args={[new THREE.BoxGeometry(1, 1, 1)]} />
//               <lineDashedMaterial color="#38BDF8" dashSize={0.1} gapSize={0.05} opacity={hoveredAxis ? 0 : (hovered ? 1 : 0)} transparent />
//             </lineSegments>
//           </>
//           : null
//         }



//         {(thisIndex === sessionData.activeIndex || thisIndex === -1) && (
//           <>
//             <group
//               position={[1, 1, 11]}
//               onPointerDown={(e) => onPointerDown(e, 'x')}
//               onPointerOver={() => {
//                 gl.domElement.style.cursor = 'grab';
//                 setHoveredAxis('x');
//               }}
//               onPointerOut={() => {
//                 gl.domElement.style.cursor = 'auto';
//                 setHoveredAxis(null);
//               }}
//             >
//               <mesh>
//                 <boxGeometry args={[2, 1, 1]} />
//                 <meshPhongMaterial color="#38BDF8" transparent opacity={hoveredAxis === 'x' ? .2 : 0} />
//               </mesh>
//               <primitive object={xArrowModel.scene.clone()} scale={0.5} />
//             </group>
//             <group
//               position={[-1, 1, 11]}
//               onPointerDown={(e) => onPointerDown(e, 'y')}
//               onPointerOver={() => {
//                 gl.domElement.style.cursor = 'grab';
//                 setHoveredAxis('y');
//               }}
//               onPointerOut={() => {
//                 gl.domElement.style.cursor = 'auto';
//                 setHoveredAxis(null);
//               }}
//             >
//               <mesh>
//                 <boxGeometry args={[1, 2, 1]} />
//                 <meshPhongMaterial color="#38BDF8" transparent opacity={hoveredAxis === 'y' ? .2 : 0} />
//               </mesh>
//               <primitive object={yArrowModel.scene.clone()} scale={0.5} />
//             </group>
//           </>
//         )}
//       </group>
//     );
//   } else {
//     return (
//       <group position={positionStart}>
//         {vizItem.mediaEmbed &&
//           <Screen
//             src={vizItem.mediaEmbed.source}
//             position={[vizItem.mediaEmbed.offset.x, vizItem.mediaEmbed.offset.y, vizItem.mediaEmbed.offset.z]}
//             scale={vizItem.mediaEmbed.scale}
//             mediaHeight={vizItem.mediaEmbed.mediaHeight || 9}
//             mediaWidth={vizItem.mediaEmbed.mediaWidth || 16}
//           />
//         }
//         <primitive
//           ref={modelRef}
//           object={model.animations.length > 0 ? model.scene : model.scene.clone()}
//           scale={scale}
//           rotation={[deg2radian(rotation[0]) || 0, deg2radian(rotation[1]) || 0, deg2radian(rotation[2]) || 0]}
//         />
//       </group>
//     );
//   }

// };

// export default React.memo(ExpModel);